import React from "react";
import {
	ADD_TYPE_SELECT_DIALOG_CANCEL,
	ADD_TYPE_SELECT_DIALOG_DESCRIPTION, ADD_TYPE_SELECT_DIALOG_POSITIVE,
	ADD_TYPE_SELECT_DIALOG_TITLE, ADD_TYPE_SELECT_LABEL, DEFAULT_SLOT_LIMIT,
	INVALID_SLOT_DELETE_MESSAGE,
	MAX_SLOT_ADD_MESSAGE,
	SLOTS_PER_PLAN
} from "../../utils/Constants";
import SelectionDialog from "../Dialogs/SelectionDialog";

export const SlotListListDialogController = (SchedulerPage) => class extends SchedulerPage {
	constructor(props) {
		super(props);
		this.selectionDialogRef = React.createRef();
	}

	dialogAction (action) {
		switch(action) {
			case "add":
				return (properties) => {
					if (properties.childSlot) {
						this.slotsRef.current.addChildSlot(properties.attributes, this.slotsRef.current.getChildDefaultAttributesContent());
					} else {
						this.slotsRef.current.addSlot(properties.attributes, this.slotsRef.current.getDefaultAttributesContent());
					}
				}
			default:
				return super.dialogAction(action);
		}
	}

	openDialog (action, passthroughVars) {
		return () => {
			let limit = this.state.planName ? SLOTS_PER_PLAN[this.props.id][this.state.planName] : DEFAULT_SLOT_LIMIT;
			switch(action) {
				case "add": {
					let options = [];
					let actionName = "Add sub-slot to current slot";
					if (this.slotsRef.current.getSize() > 0 && this.slotsRef.current.getChildSize() >= limit) {
						options.push({name: actionName, action: () => this.setState({
							snackbarOpen: true,
							snackbarMessage: `${MAX_SLOT_ADD_MESSAGE} (max ${limit})`
						})});
					} else {
						options.push({
							name: actionName, action: () => {
								let [contentFactory, defaultAttributes, attributeNameMap] = this.slotsRef.current.getChildDefaultAttributesContent();
								this.addDialogRef.current.handleClickOpen({
									contentFactory: contentFactory,
									defaultAttributes: defaultAttributes,
									attributeNameMap: attributeNameMap
								}, {...passthroughVars, childSlot: true});
							}
						});
					}
					actionName = "Add new slot";
					if (this.slotsRef.current.getSize() >= limit) {
						options.push({name: actionName, action: () => this.setState({
							snackbarOpen: true,
							snackbarMessage: `${MAX_SLOT_ADD_MESSAGE} (max ${limit})`
						})});
					} else {
						options.push({
							name: actionName, action: () => {
								let [contentFactory, defaultAttributes, attributeNameMap] = this.slotsRef.current.getDefaultAttributesContent();
								this.addDialogRef.current.handleClickOpen({
									contentFactory: contentFactory,
									defaultAttributes: defaultAttributes,
									attributeNameMap: attributeNameMap
								}, {...passthroughVars, childSlot: false});
							}
						});
					}
					if (!this.state.scheduleActive) {
						this.selectionDialogRef.current.handleClickOpen({options: options});
					} else {
						options[1].action();
					}
					break;
				}
				case "delete":
					if (this.state.scheduleActive || this.state.displayActive || this.slotsRef.current.getSize() === 0) {
						this.setState({snackbarOpen: true, snackbarMessage: INVALID_SLOT_DELETE_MESSAGE});
					} else {
						super.openDialog(action, passthroughVars)();
					}
					break;
				default:
					super.openDialog(action, passthroughVars)();
			}
		}
	}

	render() {
		return <React.Fragment>
			{super.render()}
			<SelectionDialog ref={this.selectionDialogRef} title={ADD_TYPE_SELECT_DIALOG_TITLE} description={ADD_TYPE_SELECT_DIALOG_DESCRIPTION}
				label={ADD_TYPE_SELECT_LABEL} cancelText={ADD_TYPE_SELECT_DIALOG_CANCEL} positiveText={ADD_TYPE_SELECT_DIALOG_POSITIVE}/>
		</React.Fragment>
	}
}