import * as ComponentsUtil from "./ComponentsUtil";
import { getColors, getColor } from './ColorMapper'

function getContent(attributeNames, attributes) {
    let content = [];
    if (attributeNames.includes("name") && "name" in attributes) {
        let attribute = attributes["name"];
        content.push(ComponentsUtil.generateTextField(attribute.name, "name", attribute.value, attribute.onChangeFn, 2.6, 16))
    }
    if (attributeNames.includes("hours") && "hours" in attributes) {
        let attribute = attributes["hours"];
        content.push(ComponentsUtil.generateNumberField(attribute.name, "hours", attribute.value, attribute.onChangeFn,2, 10, 0, 24));
    }
    if (attributeNames.includes("minutes") && "minutes" in attributes) {
        let attribute = attributes["minutes"];
        content.push(ComponentsUtil.generateNumberField(attribute.name, "minutes", attribute.value, attribute.onChangeFn,2, 8, 0, 60));
    }
    if (attributeNames.includes("days") && "days" in attributes) {
        let attribute = attributes["days"];
        content.push(ComponentsUtil.generateNumberField(attribute.name, "days", attribute.value || attribute.defaultValue, attribute.onChangeFn,2, 10, 0, 24));
    }
    if (attributeNames.includes("color") && "color" in attributes) {
        let attribute = attributes["color"];
        content.push(ComponentsUtil.generateColorDropDown(attribute.name, "color", attribute.value, attribute.onChangeFn,1, 2, getColors(), getColors().map(color => getColor(color, 500))));
    }
    return content;

}

export function generateTimeSlotContent(attributes) {
    let content = getContent(["name", "hours", "minutes", "color"], attributes);
    let hours = attributes["hours"].value || 0;
    let minutes = attributes["minutes"].value || 0;
    return {subtitle: `${hours > 0 ? `${hours} hour${hours !== 1 ? "s" : ""}` : ""} ${minutes || 0} minute${minutes !== 1 ? "s" : ""}`, content: content};
}

export function generateDaySlotContent(attributes) {
    let content = getContent(["name", "days", "color"], attributes);
    let days = attributes["days"].value || 0;
    return {subtitle: `${days} day${days !== 1 ? "s" : ""}`, content: content};
}

export function generateNameColorSlotContent(attributes) {
    let content = getContent(["name", "color"], attributes);
    return {content: content};
}

export function generateNameSlotContent(attributes) {
    let content = getContent(["name"], attributes);
    return {content: content};
}
