import React from 'react';
import ScheduleCardList from '../../components/ScheduleCardList';
import StaticSlotList from '../../components/StaticSlotList';
import SchedulerPage from '../SchedulerPage';
import findSolution from '../../solvers/DSolver';
import { getColor } from '../../utils/ColorMapper';
import {generateNameSlotContent} from '../../utils/SlotContentFactory';
import { MultiDisplayTable } from '../../components/MultiDisplayTable';
import { process2dData, scaleData } from '../../utils/DataUtils';
import {
    NC_ATTRIBUTE_NAME_MAP,
    DISTRIBUTION_SCHEDULER_NAME,
    MORE_EVENTS_UNASSIGNED,
    MORE_SLOTS_UNASSIGNED
} from '../../utils/Constants';
import { capitalize } from '../../utils/StringUtils';
import { withErrorBoundary } from '../../components/ErrorBoundary';
import {
    ScheduleSlotListSlotListDialogController
} from "../../components/DialogControllers/ScheduleSlotListSlotListDialogController";

class DistributionSchedulerPage extends SchedulerPage {
    constructor(props) {
        super(props);
        this.componentGenerators = {
            scheduleCard: () => <ScheduleCardList icon="/distribution_icon_free.png" contentFactory={generateNameSlotContent} attributeNameMap={NC_ATTRIBUTE_NAME_MAP}/>,
            slotList: () => <StaticSlotList contentFactory={generateNameSlotContent} attributeNameMap={NC_ATTRIBUTE_NAME_MAP} />,
            displayTable: () => <MultiDisplayTable/>
        };
    }

    findSolution(timesMaps, intervalsMaps, metadata) {
        let messages = [];
        if (!this.validateInputs(timesMaps, intervalsMaps, messages)) {
            return [{messages: messages}];
        }
        metadata["slotsInfo"] = {...this.slotsRef.current.getSlotsInfo()};
        metadata["scheduleSlotsInfo"] = {...this.scheduleRef.current.getScheduleSlotsInfo()};


        let scheduleSlotsInfo = metadata["scheduleSlotsInfo"];
        let emptySchedules = [];
        for (let i = 0; i < timesMaps.length; i += 1) {
            if (Object.keys(timesMaps[i]).length === 0) emptySchedules.push(i);
        }
        if (emptySchedules.length > 0) {
            return [{messages: [{severity: "error", content: `The following schedule slots were not filled in: ${emptySchedules.map(index => scheduleSlotsInfo.nameArray[index]).join(",")}`}]}];
        }
        let slotsInfo = metadata["slotsInfo"];
        let {result, missing} = findSolution(timesMaps, intervalsMaps, slotsInfo["lengthArray"], metadata["span"] * metadata["scale"], metadata["wrap"]);
        let solutionDatas = [];
        if (Object.keys(result).length === 0) {
            return [{messages: [{severity: "error", content: "Could not find a scheduling for any slots"}]}];
        }
        for (let [index, selectedIndex] of Object.entries(result)) {
            let timesMap = timesMaps[index];
            let intervalsMap = intervalsMaps[selectedIndex];
            let solutionData = {};
            for (let rowIndex in intervalsMap) {
                let array = [];
                solutionData[rowIndex] = array;
                let timesSchedule = timesMap[rowIndex];
                let slotsSchedule = intervalsMap[rowIndex];
                let timesIndex = 0;
                let slotsIndex = 0;
                let curr = slotsSchedule[slotsIndex][0];
                while (timesSchedule !== undefined && timesIndex < timesSchedule.length && slotsIndex < slotsSchedule.length) {
                    if (timesSchedule[timesIndex][0] <= curr) {
                        array.push({interval: timesSchedule[timesIndex], borderColor: getColor(slotsInfo["colorArray"][selectedIndex], 800), color: getColor(slotsInfo["colorArray"][selectedIndex], 500), label: [scheduleSlotsInfo.nameArray[index]]})
                        curr = timesSchedule[timesIndex][1];
                        timesIndex += 1;
                    } else if (slotsSchedule[slotsIndex][1] > timesSchedule[timesIndex][0]) {
                        array.push({interval: [Math.max(curr, slotsSchedule[slotsIndex][0]), timesSchedule[timesIndex][0]], color: getColor(slotsInfo["colorArray"][selectedIndex], 300), label: [slotsInfo["nameArray"][selectedIndex]]})
                        curr = timesSchedule[timesIndex][0];
                    } else {
                        array.push({interval: slotsSchedule[slotsIndex], color: getColor(slotsInfo["colorArray"][selectedIndex], 300), label: [slotsInfo["nameArray"][selectedIndex]]})
                        curr = slotsSchedule[slotsIndex][1];
                        slotsIndex += 1;
                    }
                }
                while (timesSchedule !== undefined && timesIndex < timesSchedule.length) {
                    array.push({interval: timesSchedule[timesIndex], borderColor: getColor(slotsInfo["colorArray"][selectedIndex], 800), color: getColor(slotsInfo["colorArray"][selectedIndex], 500), label: [scheduleSlotsInfo.nameArray[index]]})
                    timesIndex += 1;
                }
                while (slotsIndex < slotsSchedule.length) {
                    array.push({interval: [Math.max(curr, slotsSchedule[slotsIndex][0]), slotsSchedule[slotsIndex][1]], color: getColor(slotsInfo["colorArray"][selectedIndex], 300), label: [slotsInfo["nameArray"][selectedIndex]]})
                    curr = slotsSchedule[slotsIndex][1];
                    slotsIndex += 1;
                }
            }
            solutionDatas.push({title: `${scheduleSlotsInfo.nameArray[index]} → ${slotsInfo.nameArray[selectedIndex]}`, solutionData: solutionData})
        }
        if (missing.length > 0) {
            messages.push({severity: "warning", content: "Could not schedule: " + missing.map((index) => scheduleSlotsInfo["nameArray"][index]).join(", ")});
        }
        if (timesMaps.length < intervalsMaps.length) {
            messages.push({severity: "info", content: MORE_SLOTS_UNASSIGNED});
        } else if (timesMaps.length > intervalsMaps.length) {
            messages.push({severity: "info", content: MORE_EVENTS_UNASSIGNED});
        }

        let tables = [];
        for (let i = 0; i < solutionDatas.length; i += 1) {
            tables.push({messages: messages, table: solutionDatas[i]});
        }
        return tables;
    }

    processScheduleCardData(scheduleData, scheduleStartDays, scheduleEndDays, scheduleStartTimes, scheduleEndTimes, scheduleSelectAll, metadata) {
        return process2dData(scheduleData, scheduleStartDays, scheduleEndDays, scheduleStartTimes, scheduleEndTimes, scheduleSelectAll, this.state.time, metadata, this.denormalizeData, scaleData(metadata["scale"]));
    }

    componentDidMount() {
        super.componentDidMount();
        document.title = `${capitalize(DISTRIBUTION_SCHEDULER_NAME)} scheduler`;
    }
}

export default withErrorBoundary(ScheduleSlotListSlotListDialogController(DistributionSchedulerPage));