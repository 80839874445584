import React from 'react';
import DynamicScheduleCardList from '../../components/DynamicScheduleCardList';
import DynamicSlotList from '../../components/DynamicSlotList';
import DynamicSchedulerPage from './DynamicSchedulerPage';
import {
    generateNameColorSlotContent,
    generateNameSlotContent,
} from '../../utils/SlotContentFactory';
import {
    DISTRIBUTION_SCHEDULER_NAME,
    ERROR_SOLUTION_MESSAGE,
    MORE_EVENTS_UNASSIGNED,
    MORE_SLOTS_UNASSIGNED, NC_ATTRIBUTE_NAME_MAP,
    NO_SOLUTION_MESSAGE,
    PARTIAL_SOLUTION_MESSAGE
} from '../../utils/Constants';
import { capitalize } from '../../utils/StringUtils';
import { withErrorBoundary } from '../../components/ErrorBoundary';
import {getColor} from "../../utils/ColorMapper";
import {process2dData, scaleData} from "../../utils/DataUtils";
import {DynamicTabbedMultiDisplayTable} from "../../components/DynamicTabbedMultiDisplayTable";
import {
    ScheduleSlotListSlotListDialogController
} from "../../components/DialogControllers/ScheduleSlotListSlotListDialogController";

class DynamicDistributionSchedulerPage extends DynamicSchedulerPage {
    static SOLVER_NAME = "distribution";
    constructor(props) {
        super(props);
        this.componentGenerators = {
            scheduleCard: () => <DynamicScheduleCardList key="scheduleCard" icon="/distribution_icon.png" contentFactory={generateNameSlotContent} attributeNameMap={NC_ATTRIBUTE_NAME_MAP} {...this.scheduleProps()}/>,
            slotList: () => <DynamicSlotList key="slotList" contentFactory={generateNameColorSlotContent} attributeNameMap={NC_ATTRIBUTE_NAME_MAP} {...this.slotProps()}/>,
            displayTable: () => <DynamicTabbedMultiDisplayTable key="displayTable" processRequest={this.processRequest}
                processResponse={this.processResponse} solverProps={{name: DynamicDistributionSchedulerPage.SOLVER_NAME, maxSolutions: 10}} {...this.displayProps()} />
        };
    }

    async findSolution(timesMap, intervalsMaps, metadata) {
        let messages = [];
        if (!this.validateInputs(timesMap, intervalsMaps, messages)) {
            return [{messages: messages}];
        }

        metadata["slotsInfo"] = {...this.slotsRef.current.getSlotsInfo()};
        metadata["scheduleSlotsInfo"] = {...this.scheduleRef.current.getScheduleSlotsInfo()};
        let input = this.processRequest(timesMap, intervalsMaps, metadata);
        let response = await this.callSolver(DynamicDistributionSchedulerPage.SOLVER_NAME, input);
        return this.processResponse(response, metadata);
    }

    processRequest(timesMap, intervalsMaps, ) {
        let schedules = timesMap.map((time) => { return {"rows": time}; });
        let slots = intervalsMaps.map((intervalsMap) => { return {"rows": intervalsMap}});
        return {"schedules": schedules, "slots": slots};
    }

    processResponse(response, metadata) {
        if (response === undefined) {
            return [{messages: [{severity: "error", content: ERROR_SOLUTION_MESSAGE}]}];
        }
        let slotsInfo = metadata["slotsInfo"];
        let scheduleSlotsInfo = metadata["scheduleSlotsInfo"];
        let messages = [];
        if (response.status?.result === "FAILURE") {
            messages.push({severity: "error", content: NO_SOLUTION_MESSAGE});
        } else if (response.status?.result === "PARTIAL_SUCCESS") {
            messages.push({severity: "warning", content: `${PARTIAL_SOLUTION_MESSAGE} ${response.status.messageData?.failureIndices?.map((index) => scheduleSlotsInfo["nameArray"][index]).join(", ")}`});
        }
        if (slotsInfo["nameArray"].length < scheduleSlotsInfo["nameArray"].length) {
            messages.push({severity: "info", content: MORE_EVENTS_UNASSIGNED});
        } else if (slotsInfo["nameArray"].length > scheduleSlotsInfo["nameArray"].length) {
            messages.push({severity: "info", content: MORE_SLOTS_UNASSIGNED});
        }

        let tables = response.schedule.map((schedule, index) => {
            let solutionData = {};
            for (let rowIndex of Object.keys(schedule.table)) {
                solutionData[rowIndex] = schedule.table[rowIndex].map((entry) => {
                    let overlap = entry.data.overlap;
                    return {"interval": [entry.start, entry.end], "borderColor": getColor(slotsInfo["colorArray"][index], 800), "color": getColor(slotsInfo["colorArray"][index], overlap ? 500 : 200), "label": [overlap ? slotsInfo["nameArray"][schedule.data.assignmentIndex] : scheduleSlotsInfo["nameArray"][index]]};
                });
            }
            return {solutionData: solutionData, title: `${scheduleSlotsInfo.nameArray[index]} → ${slotsInfo.nameArray[schedule.data.assignmentIndex]}`};
        });
        return tables.map((table) => { return{messages: messages, table: table}});
    }

    processScheduleCardData(scheduleData, scheduleStartDays, scheduleEndDays, scheduleStartTimes, scheduleEndTimes, scheduleSelectAll, metadata) {
        return process2dData(scheduleData, scheduleStartDays, scheduleEndDays, scheduleStartTimes, scheduleEndTimes, scheduleSelectAll, this.state.time, metadata, this.denormalizeData, scaleData(metadata["scale"]));
    }

    componentDidMount() {
        super.componentDidMount();
        document.title = `${capitalize(DISTRIBUTION_SCHEDULER_NAME)} scheduler | Snug Scheduler`;
    }
}

export default withErrorBoundary(ScheduleSlotListSlotListDialogController(DynamicDistributionSchedulerPage));