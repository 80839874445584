import {
	DEFAULT_SLOT_LIMIT,
	INVALID_SLOT_DELETE_MESSAGE,
	MAX_SLOT_ADD_MESSAGE,
	SLOTS_PER_PLAN
} from "../../utils/Constants";

export const SlotListDialogController = (SchedulerPage) => class extends SchedulerPage {
	openDialog (action, passthroughVars) {
		return () => {
			let limit = this.state.planName ? SLOTS_PER_PLAN[this.props.id][this.state.planName] : DEFAULT_SLOT_LIMIT;
			switch(action) {
				case "add":
					if (this.slotsRef.current.getSize() >= limit) {
						this.setState({snackbarOpen: true, snackbarMessage: `${MAX_SLOT_ADD_MESSAGE} (max ${limit})`});
					} else {
						super.openDialog(action, passthroughVars)();
					}
					break;
				case "delete":
					if (this.state.scheduleActive || this.state.displayActive || this.slotsRef.current.getSize() === 0) {
						this.setState({snackbarOpen: true, snackbarMessage: INVALID_SLOT_DELETE_MESSAGE});
					} else {
						super.openDialog(action, passthroughVars)();
					}
					break;
				default:
					super.openDialog(action, passthroughVars)();
			}
		}
	}
}