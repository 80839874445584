import React from "react";
import { MultiDisplayTable } from "./MultiDisplayTable";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import {DisplayTabs} from "./DisplayTabs";
import TextField from "@mui/material/TextField";
import { getColor } from '../utils/ColorMapper';
import { deleteDoc, doc, onSnapshot, orderBy, query, updateDoc} from "firebase/firestore";
import Stack from "@mui/material/Stack";
import {findInsertedOrder} from "../utils/OrderEncoderUtils";
import EmptyTable from "../pages/EmptyTable";
import {OPERATION_FAILED, WRITE_FAILED_DETAIL} from "../utils/Constants";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress';
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";
import lodash from "lodash";
import {initializeSolutionDocumentData} from "../utils/DocumentUtils";

export class DynamicTabbedMultiDisplayTable extends React.Component {
    constructor(props) {
        super(props);
        this.displayTableRef = React.createRef();
        this.onDragEnd = this.onDragEnd.bind(this);
        this.state = {activeTabId: this.props.solution?.[0]?.id, idToIndex: Object.fromEntries(this.props.solution.map(((item, index) => [item.id, index]))), solution: this.props.solution, title: this.props.solution[0]?.title || ""};
        onSnapshot(query(this.props.rootPath, orderBy("order")), (snapshot) => {
            let solution = [];
            snapshot.forEach((doc) => {
                let data = doc.data();
                initializeSolutionDocumentData(data);
                solution.push(data);
                data.id = doc.id;
            });
            let idToIndex = Object.fromEntries(solution.map(((item, index) => [item.id, index])));
            let newActiveTabId = this.state.activeTabId in idToIndex ? this.state.activeTabId : solution[0]?.id;
            this.setState({activeTabId: newActiveTabId, idToIndex: idToIndex, solution: solution, title: solution[idToIndex[newActiveTabId]]?.title || ""});
        });
    }

    onDragEnd(result) {
        const { destination, source, } = result;
        if (!destination) {
            return;
        }
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        let solution = [...this.state.solution];
        let removedSolution = solution.splice(source.index, 1)[0];
        let orders = solution.map((sol) => sol.order);
        let newOrder = findInsertedOrder(orders, destination.index);
        solution.splice(destination.index, 0, removedSolution);
        let idToIndex = Object.fromEntries(solution.map(((item, index) => [item.id, index])));
        this.setState({solution: solution, idToIndex: idToIndex, activeTabId: solution[destination.index].id}, () => {
            updateDoc(doc(this.props.rootPath, removedSolution.id), {
                order: newOrder
            }).catch(() => {
                this.props.displayMessage(`${OPERATION_FAILED} ${WRITE_FAILED_DETAIL}`);
            });
        });
    }

    setActiveId(id) {
        this.saveTitleToActive(this.state.title);
        this.setState({activeTabId: id, title: this.state.solution[this.state.idToIndex[id]].title});
    }

    deleteTab = (index) => {
        let solution = [...this.state.solution];
        let activeTabId = this.state.activeTabId;
        let removedSolution = solution.splice(index, 1)[0];
        let idToIndex = Object.fromEntries(solution.map(((item, index) => [item.id, index])));
        if (removedSolution.id === this.state.activeTabId) {
            let activeTabIndex = this.state.idToIndex[this.state.activeTabId];
            activeTabId = (solution[activeTabIndex] || solution[activeTabIndex - 1])?.id;
        }
        this.setState({solution: solution, activeTabId: activeTabId, idToIndex: idToIndex}, () => {
            deleteDoc(doc(this.props.rootPath, removedSolution.id));
        });
    }

    onChangeTitle = (event) => {
        this.setState({title: event.target.value});
    }

    onSaveTitle = (event) => {
        this.saveTitleToActive(event.target.value);
    }

    saveTitleToActive = (title) => {
        updateDoc(doc(this.props.rootPath, this.state.activeTabId), {
            "title": title,
        }).catch(() => {
            this.props.displayMessage(`${OPERATION_FAILED} ${WRITE_FAILED_DETAIL}`);
        });
    }

    onTitleEnter = (event) => {
        if (event.key === 'Enter') {
            event.target.blur();
        }
    }

    onRefresh = async () => {
        let responses;
        let solutionId = this.state.activeTabId;
        let activeSolution = this.state.solution[this.state.idToIndex[this.state.activeTabId]];
        if (solutionId && this.props.displayCache?.[solutionId]) {
            responses = this.props.displayCache[solutionId].slice(1);
        } else {
            let input = this.props.processRequest(activeSolution.times, activeSolution.intervalsMaps, activeSolution.metadata);
            this.setState({refreshing: true});
            try {
                const httpResponse = await httpsCallable(functions, 'solve')({
                    "solver": this.props.solverProps.name,
                    "input": input,
                    variant: "solveAll",
                    maxSolutions: this.props.solverProps.maxSolutions
                });
                responses = httpResponse.data.map((response) => this.props.processResponse(response, activeSolution.metadata));
            } catch (error) {
                this.props.displayMessage("Error occurred while fetching new solutions");
            } finally {
                this.setState({refreshing: false});
            }
            //Remove duplicate response
            let uniqueResponses = [activeSolution.data];
            responses.forEach(response => {
                if (!uniqueResponses.some(uniqueResponse => lodash.isEqual(response, uniqueResponse))) {
                    uniqueResponses.push(response);
                }
            });
            responses = uniqueResponses.slice(1)
        }
        if (responses.length > 0) {
            let newSolution = {...activeSolution};
            newSolution.data = responses[0];
            let newSolutionId = await this.props.saveSolution(newSolution);
            this.props.saveDisplayCache({...this.props.displayCache, [newSolutionId]: responses});
        } else {
            this.props.displayMessage("No new solutions were found");
        }
    }

    getPdfComponents() {
        if (!this.displayTableRef.current) {
            return undefined;
        }
        return this.displayTableRef.current.getPdfComponents();
    }

    render() {
        let activeTabIndex = this.state.idToIndex[this.state.activeTabId];
        if (this.state.solution === undefined || this.state.solution[activeTabIndex] === undefined) {
            return <EmptyTable/>;
        }
        return <React.Fragment>
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                    {(provided) => (
                        <Stack ref={provided.innerRef} {...provided.droppableProps} direction="row" overflow="hidden" backgroundColor={getColor("blueGrey", 50)}>
                            {this.state.solution.map((sol, index) => {
                                return <React.Fragment key={index}>
                                    <DisplayTabs id={sol.id} width={100 / this.state.solution.length} index={index} name={sol.title} divider={activeTabIndex !== index && activeTabIndex !== index + 1}
                                         onPressAction={() => this.setActiveId(sol.id)} deleteAction={() => this.deleteTab(index)} active={sol.id === this.state.activeTabId}>
                                    </DisplayTabs>
                                </React.Fragment>
                            })}
                            {provided.placeholder}
                        </Stack>
                    )}
                </Droppable>
            </DragDropContext>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', boxSizing: 'border-box', pr: 1 }}>
                <TextField sx={{boxSizing: "border-box", pt: 0.5, pl: 2, pr: 2}} value={this.state.title} id="outlined-basic" variant="standard"
                fullWidth InputProps={{disableUnderline: true, style: {fontWeight: 'bold', fontSize: "1.25rem"}}} onBlur={this.onSaveTitle}
                        onKeyDown={this.onTitleEnter} onChange={this.onChangeTitle} />
                {this.props.processResponse && this.props.processRequest && this.props.solverProps && this.state.solution && this.state.solution[this.state.idToIndex[this.state.activeTabId]] &&
                    <Tooltip title="Find another solution">
                        <IconButton aria-label="recompute" sx={{ color: getColor("blue", 800), fontSize: '1.6rem' }} onClick={this.onRefresh}>
                            {this.state.refreshing ? <CircularProgress size="1.25rem" thickness={5} /> : <RefreshIcon fontSize="inherit" />}
                        </IconButton>
                    </Tooltip>
                }
            </Box>
            <MultiDisplayTable ref={this.displayTableRef} key={this.state.activeTabId} solution={this.state.solution[activeTabIndex]} height={this.props.height} />
        </React.Fragment>
    }
}