export function UniqueNameGeneratorFactory(unit) {
	return (names) => {
		const existingNames = new Set(names);
		let name = `Untitled ${unit}`;
		let index = 1;
		while (existingNames.has(name)) {
			name = `Untitled ${unit} (${index++})`;
		}
		return name;
	}
}

export function IncrementingGeneratorFactory(baseName) {
	return (names) => {
		const existingNames = new Set(names);
		let index = 1;
		let name = `${baseName} ${index++}`;
		while (existingNames.has(name)) {
			name = `${baseName} ${index++}`;
		}
		return name;
	}
}