import React from 'react';
import { MultiClickTable } from '../../components/MultiClickTable';
import { MultiCheckTable } from '../../components/MultiCheckTable';
import ScheduleCard from '../../components/ScheduleCard';
import StaticSlotList from '../../components/StaticSlotList';
import { TouchTable } from '../../components/TouchTable';
import SchedulerPage from '../SchedulerPage';
import findSolution from '../../solvers/FSolver';
import { ClickTable } from '../../components/ClickTable';
import { CheckTable } from '../../components/CheckTable';
import { getColor } from '../../utils/ColorMapper';
import {
    generateDaySlotContent,
    generateTimeSlotContent
} from '../../utils/SlotContentFactory';
import { MultiDisplayTable } from '../../components/MultiDisplayTable';
import {
    FULFILLMENT_SCHEDULER_NAME,
    PREF_COMPLETE_ATTRIBUTE_NAME_MAP
} from '../../utils/Constants';
import { capitalize } from '../../utils/StringUtils';
import { withErrorBoundary } from '../../components/ErrorBoundary';
import {SlotListDialogController} from "../../components/DialogControllers/SlotListDialogController";

class FulFillmentSchedulerPage extends SchedulerPage {
    constructor(props) {
        super(props);
        this.componentGenerators = {
            scheduleCard: () => <ScheduleCard icon="/fulfillment_logo.svg"/>,
            slotList: () => <StaticSlotList contentFactory={this.state.time ? generateTimeSlotContent : generateDaySlotContent} attributeNameMap={PREF_COMPLETE_ATTRIBUTE_NAME_MAP}/>,
            displayTable: () => <MultiDisplayTable/>
        };
    }
    
    findSolution(timesMap, intervalsMaps, metadata) {
        let messages = [];
        if (!this.validateInputs(timesMap, intervalsMaps, messages)) {
            return [{messages: messages}];
        }
        metadata["slotsInfo"] = {...this.slotsRef.current.getSlotsInfo()};

        let slotsInfo = metadata["slotsInfo"];
        let prefTotalArray;
        if (this.state.time) {
            prefTotalArray = slotsInfo["hoursArray"].map((hours, index) => ((hours || 0) * 60) + (slotsInfo["minutesArray"][index] || 0));
        } else {
            prefTotalArray = slotsInfo["daysArray"];
        }
        let {density, schedules, missing} = findSolution(timesMap, intervalsMaps, prefTotalArray);
        if (schedules === undefined) {
            return undefined;
        }
        let solutionDatas = [];
        let densityData = {};
        for (let rowIndex in density) {
            if (densityData[rowIndex] === undefined) { 
                densityData[rowIndex] = [];
            }
            let array = densityData[rowIndex];
            for (let {interval, magnitude, capacity} of density[rowIndex]) {
                array.push({interval: interval, borderColor: getColor("green", magnitude / capacity * 800 + 300), color: getColor("green", magnitude / capacity * 700 + 100), label: [`${magnitude} of ${capacity} slots filled`]})
            }
        }
        solutionDatas.push({title: "Overview", solutionData: densityData})
        for (let i of Object.keys(schedules)) {
            let solutionData = {};
            for (let rowIndex in schedules[i]) {
                if (solutionData[rowIndex] === undefined) { 
                    solutionData[rowIndex] = [];
                }
                let array = solutionData[rowIndex];
                for (let interval of schedules[i][rowIndex]) {
                    array.push({interval: interval, borderColor: getColor(slotsInfo["colorArray"][i], 800), color: getColor(slotsInfo["colorArray"][i], 500), label: [slotsInfo["nameArray"][i]]})
                }
            }
            solutionDatas.push({title: `${slotsInfo.nameArray[i]}`, solutionData: solutionData})
        }
        if (missing.length > 0) {
            messages.push({severity: "warning", content: "Could not schedule: " + missing.map((index) => slotsInfo["nameArray"][index]).join(", ")});
        }

        let tables = [];
        for (let i = 0; i < solutionDatas.length; i += 1) {
            tables.push({messages: messages[i], table: solutionDatas[i]});
        }
        return tables;
    }

    getTableType() {
        if (window.navigator.userAgent.match(/(iPhone|iPod|iPad|Android)/i)) {
            return this.state.complexTableType ? TouchTable : MultiCheckTable;
        } else {
            if (this.state.scheduleActive) {
                return this.state.complexTableType ? MultiClickTable : MultiCheckTable;
            }
            return this.state.complexTableType ? ClickTable : CheckTable;
        }
    }

    componentDidMount() {
        super.componentDidMount();
        document.title = `${capitalize(FULFILLMENT_SCHEDULER_NAME)} scheduler`;
    }
}

export default withErrorBoundary(SlotListDialogController(FulFillmentSchedulerPage));