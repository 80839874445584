import React from "react";
import { DisplayTable } from "./DisplayTable";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Typography from '@mui/material/Typography';
import IconButton from "@mui/material/IconButton";


export class MultiDisplayTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {index: 0};
        this.displayRef = React.createRef();
    }

    increment = () => {
        if (this.state.index < this.props.solution.data.length - 1) {
            this.setState({index: this.state.index + 1})
        }
    }

    decrement = () => {
        if (this.state.index > 0) {
            this.setState({index: this.state.index - 1})
        }
    }

    getPdfComponents() {
        if (this.props.solution === undefined || this.props.solution.data?.every((data) => data.table?.solutionData) !== undefined) {
            return undefined;
        }
        let tables = this.props.solution.data?.map((data) => data.table);
        return tables.map((table, index) => {
            return <DisplayTable key={index} data={{solutionData: table.solutionData}}
                                 tableRows={this.props.solution.rows} tableCols={this.props.solution.cols} height={this.props.height}
                                 scale={this.props.solution.metadata.scale} start={this.props.solution.metadata.start}
                                 interval={this.props.solution.metadata.interval} timeFormat={this.props.solution.timeFormat}
                                 range={this.props.solution.metadata.range} showNames={this.displayRef?.current?.state.showNames} omitIcon={true}/>
        })
    }

    //this.props.solution.data - [{title: "Schedule 1 → Slot 1", data: {0: [{interval, color, label}, ...], 1: ...}}, ...]
    render() {
        let tables = this.props.solution?.data?.map((data) => data.table);
        let messages = this.props.solution?.data?.map((data) => data.messages);
        if (tables === undefined || tables[this.state.index] === undefined) {
            return <DisplayTable data={{messages: messages[this.state.index]}} tableRows={this.props.solution.rows} tableCols={this.props.solution.cols}
                 height={this.props.height} scale={this.props.solution.metadata.scale} start={this.props.solution.metadata.start}
                 interval={this.props.solution.metadata.interval} timeFormat={this.props.solution.timeFormat}
                 range={this.props.solution.metadata.range} />
        }
        return <React.Fragment>
            {(tables[this.state.index].title || tables.length > 1) && <Stack direction="row" spacing={2} p={1} pl={2} pr={2} justifyContent="space-between" alignItems="center">
                <Typography noWrap sx={{ fontWeight: 'bold' }}>{tables[this.state.index].title}</Typography>
                {tables.length > 1 && <Box component="section" sx={{display:'flex', alignItems: "center"}}>
                    <IconButton onClick={this.decrement}>
                        <ArrowCircleLeftIcon style={{ color: this.state.index === 0 ? 'grey' : 'green' }} />
                    </IconButton>
                    <Typography noWrap ml={1} mr={1}>{this.state.index + 1} of {tables.length}</Typography>
                    <IconButton onClick={this.increment}>
                        <ArrowCircleRightIcon style={{ color: this.state.index === tables.length - 1 ? 'grey' : 'green' }}/>
                    </IconButton>
                </Box>}
            </Stack>}
            <DisplayTable ref={this.displayRef} data={{solutionData: tables[this.state.index].solutionData, messages: messages[this.state.index]}}
                tableRows={this.props.solution.rows} tableCols={this.props.solution.cols} height={this.props.height}
                scale={this.props.solution.metadata.scale} start={this.props.solution.metadata.start}
                interval={this.props.solution.metadata.interval} timeFormat={this.props.solution.timeFormat}
                range={this.props.solution.metadata.range} showUnit={this.props.showUnit} showFn={this.props.showFn}/>
        </React.Fragment>
    }
}