import React from 'react';
import ScheduleCard from '../../components/ScheduleCard';
import SchedulerPage from '../SchedulerPage';
import findSolution from '../../solvers/SSolver';
import { getColor } from '../../utils/ColorMapper';
import {generateNameColorSlotContent} from '../../utils/SlotContentFactory';
import StaticSlotListList from '../../components/StaticSlotListList';
import { process3dData, scaleData } from '../../utils/DataUtils';
import {
    NC_ATTRIBUTE_NAME_MAP,
    SELECTION_SCHEDULER_NAME
} from '../../utils/Constants';
import { capitalize } from '../../utils/StringUtils';
import { withErrorBoundary } from '../../components/ErrorBoundary';
import {MultiDisplayTable} from "../../components/MultiDisplayTable";
import {SlotListListDialogController} from "../../components/DialogControllers/SlotListListDialogController";

class SelectionSchedulerPage extends SchedulerPage {
    constructor(props) {
        super(props);
        this.selectionDialogRef = React.createRef();
        this.componentGenerators = {
            scheduleCard: () => <ScheduleCard icon="/selection_icon_free.png"/>,
            slotList: () => <StaticSlotListList contentFactory={generateNameColorSlotContent} attributeNameMap={NC_ATTRIBUTE_NAME_MAP}/>,
            displayTable: () => <MultiDisplayTable/>
        };
    }

    findSolution(timesMap, intervalsMaps, metadata) {
        let messages = [];
        if (!this.validateInputs(timesMap, intervalsMaps, messages)) {
            return [{messages: messages}];
        }

        metadata["slotsInfo"] = {...this.slotsRef.current.getSlotsInfo()};
        let slotsInfo = metadata["slotsInfo"];
        let {result, missing} = findSolution(timesMap, intervalsMaps, slotsInfo["lengthArray"], metadata["span"] * metadata["scale"], metadata["wrap"]);
        let solutionData = {};
        if (result === undefined || Object.keys(result).length === 0) {
            return [{messages: [{severity: "error", content: "Could not find an arrangement for any slot"}]}];
        }
        for (let [index, selectedIndex] of Object.entries(result)) {
            let selectedMap = intervalsMaps[index][selectedIndex];
            for (let rowIndex in selectedMap) {
                if (solutionData[rowIndex] === undefined) {
                    solutionData[rowIndex] = [];
                }

                let array = solutionData[rowIndex];
                for (let j = 0; j < selectedMap[rowIndex].length; j += 1) {
                    array.push({interval: selectedMap[rowIndex][j], borderColor: getColor(slotsInfo["colorArray"][index], 800), color: getColor(slotsInfo["colorArray"][index], 500), label: [slotsInfo["nameArray"][index]]})
                }
            }
        }
        if (missing.length > 0) {
            messages.push({severity: "warning", content: "Could not schedule: " + missing.map((index) => slotsInfo["nameArray"][index]).join(", ")});
        }
        return [{messages: messages, table: {solutionData: solutionData}}];
    }

    processSlotListData(slotListDatas, slotListStartDaysList, slotListEndDaysList, slotListStartTimesList, slotListEndTimesList, slotListSelectAlls, metadata) {
        return process3dData(slotListDatas, slotListStartDaysList, slotListEndDaysList, slotListStartTimesList, slotListEndTimesList, slotListSelectAlls, this.state.time, metadata, this.denormalizeData, scaleData(metadata["scale"]));
    }

    componentDidMount() {
        super.componentDidMount();
        document.title = `${capitalize(SELECTION_SCHEDULER_NAME)} scheduler`;
    }
}

export default withErrorBoundary(SlotListListDialogController(SelectionSchedulerPage));