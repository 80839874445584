import React from 'react';
import SchedulerPage from '../SchedulerPage';
import LoadingPage from '../LoadingPage';
import TextPage from '../TextPage';
import {db, functions} from "../../firebase"
import { doc, collection } from "firebase/firestore";
import ShareIcon from '@mui/icons-material/Share';
import SaveIcon from '@mui/icons-material/Save';
import {NOT_FOUND_TEXT, NOT_FOUND_SUBTEXT, CHANGES_SAVED_ALERT, FREE_PLAN_NAME} from '../../utils/Constants';
import { log } from '../../utils/AnalyticsUtils';
import {httpsCallable} from "firebase/functions";
import {isMac} from "../../utils/PlatformUtils";

export default class DynamicSchedulerPage extends SchedulerPage {
    constructor(props) {
        super(props);
        this.state.scheduleLoading = true;
        this.state.slotLoading = true;
        let rootPath = doc(db, "schedules", this.props.params.id);
        this.scheduleProps = () => {
            return {ref: this.scheduleRef, rootPath: doc(db, "schedules", this.props.params.id), hide: this.state.scheduleLoading || this.state.slotLoading, tableChangeAction: this.tableChangeAction, propChangeAction: this.propChangeAction, onLoadFinish: () => this.setState({scheduleLoading: false}), displayMessage: this.displayMessage};
        }
        this.slotProps = () => {
            return {ref: this.slotsRef, rootPath: collection(rootPath, "slots"), hide: this.state.scheduleLoading || this.state.slotLoading, propChangeAction: this.propChangeAction, onLoadFinish: () => this.setState({slotLoading: false}),  displayMessage: this.displayMessage}
        };
        this.displayProps = () => {
            return {rootPath: collection(rootPath, "solutions"), displayCache: this.state.displayCache, saveDisplayCache: this.saveDisplayCache, saveSolution: async (solution) => await this.scheduleRef.current.saveSolution(solution)}
        }
        this.state.planName = FREE_PLAN_NAME;
        props.auth.currentUser.getIdTokenResult().then((idTokenResult) => {
            this.setState({planName: idTokenResult.claims?.stripeRole || FREE_PLAN_NAME});
        });
    }

    getActions(metadata) {
        let actions = super.getActions(metadata);
        actions[1].icon = <SaveIcon />;
        actions[1].action = this.saveOnClick;
        actions[3].icon = <ShareIcon />
        actions[3].action = this.openDialog("share");
        return actions;
    }

    openDialog (action, passthroughVars) {
        return () => {
            switch(action) {
                case "share": 
                    log("button", {name: "Share"})
                    this.shareDialogRef.current.handleClickOpen({...this.scheduleRef.current.getSharingDetails(), scheduleId: this.props.params.id});
                    break;
                default:
                    super.openDialog(action, passthroughVars)();
            }
        }
    }

    saveOnClick = () => {
        log("button", {name: "Save"})
        this.saveToActiveSlot();
        this.displayMessage(CHANGES_SAVED_ALERT);
    }

    getDownloadSolution = () => {
        return this.scheduleRef.current.getSolution().slice(-1)[0];
    }

    callSolver = (solver, input) => {
        return new Promise(resolve => {
            httpsCallable(functions, 'solve')({"solver": solver, "input": input})
                .then(response => {
                    //console.log("apiresponse", response.data);
                    resolve(response.data || {});
                }).catch(() => {
                    resolve(undefined);
            });
        });
    }

    saveDisplayCache = (state) => {
        this.setState({displayCache: state});
    }

    render() {
        if (this.state.notFound) {
            return <TextPage text={NOT_FOUND_TEXT} subtext={NOT_FOUND_SUBTEXT} />
        }

        if (this.state.scheduleLoading || this.state.slotLoading) {
            return <React.Fragment key="loadingFragment">
                <LoadingPage key="scheduleLoading"/>
                {this.componentGenerators.scheduleCard()}
                {this.componentGenerators.slotList()}
            </React.Fragment>
        }
        return super.render();
    }

    onBeforeUnload = () => {
        if (this.tableRef.current && this.getRef()) {
            this.saveToActiveSlot();
        }
    };

    handleKeyDown(event) {
        const ctrlKey = isMac() ? event.metaKey : event.ctrlKey;
        if (ctrlKey && !event.shiftKey && event.key === "s") {
            event.preventDefault();
            this.saveOnClick();
        }
    }

    componentDidMount() {
        super.componentDidMount();
        window.addEventListener("beforeunload", this.onBeforeUnload);
        this.autoSaveInterval = setInterval(this.saveToActiveSlot, 1800000); // 30 minutes

    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener("beforeunload", this.onBeforeUnload);
        clearInterval(this.autoSaveInterval);
    }
}