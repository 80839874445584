import React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';

export function generateTextField(label, key, value, onChange, flexGrow, flexBasis) {
    return <TextField id={key} label={label} value={value} onChange={onChange} style={{flexGrow: flexGrow, flexBasis: `${flexBasis + label.length}ch`}} variant="outlined"
        onFocus={e => {e.target.select();}} />
}

export function generateColorDropDown(label, key, value, onChange, flexGrow, flexBasis, options, values) {
    return <TextField id={key} label={label} value={value} onChange={onChange} type="text" style={{flexGrow: flexGrow, flexBasis: `${flexBasis + label.length}ch`}} select >
        {options.map((option, index) => (
        <MenuItem key={option} value={option}>
            <Box sx={{ width: "100%", height: 20, bgcolor: values[index] }} />
        </MenuItem>
        ))}
    </TextField>
}

export function generateNumberField(label, key, value, onChange, flexGrow, flexBasis, min, max) {
    return <TextField id={key} label={label} value={value} onChange={onChange} style={{flexGrow: flexGrow, flexBasis: `${flexBasis + label.length}ch`}} variant="outlined" type="number" sx={{input: {textAlign: "center"}}}
        InputProps={{inputProps: { min: min, max: max }, endAdornment:<InputAdornment position="end">{label}</InputAdornment>}}/>
}