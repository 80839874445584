export default class SlotAttribute {
    constructor(name, value, onChangeFn) {
        this.name = name;
        this.value = value;
        this.onChangeFn = onChangeFn;
    }

    clone() {
        return new SlotAttribute(this.name, this.value, this.onChangeFn);
    }
}