import React from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PopUpDialog from './PopUpDialog';
import Box from '@mui/material/Box';
import { defaultValues } from '../../utils/DataUtils';
import SlotAttribute from "../SlotAttribute";

export default class AddSlotDialog extends PopUpDialog {
	constructor(props) {
		super(props);
	}

	handleChange(field) {
		return (event) => {
			let value = event.target.value;
			if (field === "minutes" || field === "days" || field === "hours") {
				value = parseInt(value);
			}
			this.setState({[field]: value});
		}
	}

	handleClickOpen (inputState, passthroughVars) {
		Object.entries(inputState.defaultAttributes)?.forEach(([key, value]) => {
			this.setState({[key]: value});
		});
		this.passthroughVars = passthroughVars;
		super.handleClickOpen(inputState);
	}

	handleClose (success) {
		return () => {
			if (success) {
				let attributes = Object.fromEntries(Object.keys(this.state.inputState.attributeNameMap).filter((key) => this.state[key] !== undefined).map((key) => [key, this.state[key]]));
				defaultValues(attributes, this.state.inputState.defaultAttributes);
				this.props.action({attributes: attributes, ...this.passthroughVars});
			}
			super.handleClose(success)();
		}
	}

	getDialogContent() {
		if (!this.state.inputState.defaultAttributes || !this.state.inputState.contentFactory) {
			return;
		}
		let slotAttributes = Object.fromEntries(Object.entries(this.state.inputState.defaultAttributes).map(([key]) => [key, new SlotAttribute(this.state.inputState.attributeNameMap[key], this.state[key], this.handleChange(key))]));
		let {content} = this.state.inputState.contentFactory(slotAttributes);
		return <React.Fragment>
			<DialogTitle>Add a new slot</DialogTitle>
			<DialogContent>
				<Box display="flex" flexWrap="wrap" gap={1} m={1}>
					{content?.map((component, index) => (
						<React.Fragment key={index}>
							{React.cloneElement(component, {autoFocus: index === 0}) }
						</React.Fragment>
					))}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.handleClose(false)}>Cancel</Button>
				<Button onClick={this.handleClose(true)}>Add</Button>
			</DialogActions>
		</React.Fragment>
	}
}