import React from "react";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import { Draggable } from "react-beautiful-dnd"
import { getColor } from '../utils/ColorMapper'
import { print } from "../utils/DataUtils";
import SlotAttribute from "./SlotAttribute";

export default class Slot extends React.Component {
  constructor(props) {
      super(props);
      //init name/color for content that does not contain name/color
      let state = {expanded: false};
      for (const key in this.props.attributes) {
        state[key] = this.props.attributes[key];
      }
      this.state = state;
  }

  handleChange(field, value) {
    switch (field) {
      case "expand":
        this.setState({expanded: !this.state.expanded});
        this.props.propChangeAction("expand", !this.state.expanded);
        break;
      case "color":
        this.setState({[field]: value}, () => {
          if (this.props.selected) {
            this.props.propChangeAction(field, value);
          }
        });
        break;
      case "days":
      case "hours":
      case "minutes":
        this.setState({[field]: parseInt(value)});
        break;
      default:
        this.setState({[field]: value})
    }
  }

  handleEventChange(field) {
    return (event) => {
      let value = event.target.value;
      this.handleChange(field, value);
    }
  }

  setExpanded(expanded) {
    this.setState({expanded: expanded})
  }

  setColor(color) {
    this.handleChange("color", color);
  }

  slotOnClick = (event) => {
    event.stopPropagation();
    this.props.slotClickAction({color: this.state.color, data: this.getScheduleData()});
  }

  initSlotAttributes() {
    return Object.fromEntries(Object.entries(this.props.attributeNameMap).map(([key]) => [key, new SlotAttribute(this.props.attributeNameMap[key], this.state[key], this.handleEventChange(key))]));
  }

  render() {
    let {subtitle, content: contentList} = this.props.slotContentFactory(this.initSlotAttributes());

    return (
      <Draggable draggableId={this.props.id} index={this.props.index}>
        {(provided) => (
          <div  {...provided.draggableProps}>
            <Card style={{width: "100%", backgroundColor: this.props.selected ? getColor(this.state.color, this.props.selectedShade || 50) : null}} ref={provided.innerRef} sx={{ border: 1, borderColor: getColor(this.state.color, 500) }} onClick={this.slotOnClick} >
              <CardHeader
                avatar={
                  <Box {...provided.dragHandleProps} sx={{ width: 10, height: 40, bgcolor: getColor(this.state.color, 500) }} onMouseDown={() => this.setExpanded(false)}/>
                }
                action={
                  <IconButton aria-label="settings" onClick={this.handleEventChange("expand")}>
                    <SettingsIcon />
                  </IconButton>
                }
                sx={{
                  overflow: "hidden",
                  "& .MuiCardHeader-content": {
                    overflow: "hidden"
                  }
                }}
                title={this.state.name}
                titleTypographyProps={{ noWrap: true }}
                subheader={subtitle}
                subheaderTypographyProps={{ noWrap: true }}
              />
              {(contentList.length || this.props.auxContentList.length) && <Collapse in={this.state.expanded} timeout="auto">
                <CardContent>
                  <Box display="flex" flexWrap="wrap" gap={1}>
                      {contentList.map((contentMap, index) => (
                          <React.Fragment key={index}>
                            {contentMap}
                          </React.Fragment>
                      ))}
                  </Box>
                  {this.props.auxContentList.map((content, index) => (
                        <React.Fragment key={index}>
                          {content}
                        </React.Fragment>
                  ))}
                </CardContent>
              </Collapse>}
            </Card>
          </div>
        )}
      </Draggable>
    );
  }

  getSlotInfo() {
    let slotInfo = {};
    for (const key in this.props.attributes) {
      slotInfo[key] = this.state[key];
    }
    return slotInfo;
  }

  getScheduleData() {
    if (this.scheduleData) {
      return [this.scheduleData.data, this.scheduleData.scheduleStartDay, this.scheduleData.scheduleEndDay, this.scheduleData.scheduleStartTime, this.scheduleData.scheduleEndTime, this.scheduleData.timeFormat, this.scheduleData.selectAll];
    }
    return [undefined, undefined, undefined, undefined, undefined, true, undefined];
  }

  saveData(data, timeFormat, scheduleStartDay, scheduleEndDay, scheduleStartTime, scheduleEndTime, selectAll) {
    if (data !== undefined) { //there are use cases where we only want to update attributes that data will intentionally be undefined for dynamic
      this.scheduleData = {
        timeFormat: timeFormat,
        data: data,
        scheduleStartDay: scheduleStartDay,
        scheduleEndDay: scheduleEndDay,
        scheduleStartTime: scheduleStartTime,
        scheduleEndTime: scheduleEndTime,
        selectAll: selectAll
      };
    }
  }

  //TODO: Fix name/length
  toString(startDay, endDay, startTime, endTime, startDate, timeFormat) {
    let [data, , , , , scheduleTimeFormat, selectAll] = this.getScheduleData();
    let {subtitle} = this.props.slotContentFactory(this.initSlotAttributes());
    let title = `${this.state.name}${subtitle ? ` (${subtitle})` : ""}`;
    return print(title, data, startDay, endDay, startTime, endTime, selectAll, scheduleTimeFormat, startDate, timeFormat);
  }
}