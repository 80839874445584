import {
	DEFAULT_SLOT_LIMIT,
	INVALID_SLOT_DELETE_MESSAGE,
	MAX_SLOT_ADD_MESSAGE,
	MIN_SLOT_DELETE_MESSAGE,
	SLOTS_PER_PLAN
} from "../../utils/Constants";

export const ScheduleSlotListSlotListDialogController = (SchedulerPage) => class extends SchedulerPage {
	dialogAction (action) {
		switch(action) {
			case "add":
				return (properties) => {
					if (properties.slotsAdd) {
						this.slotsRef.current.addSlot(properties.attributes);
					} else {
						this.scheduleRef.current.addSlot(properties.attributes);
					}
				}
			default:
				return super.dialogAction(action);
		}
	}

	openDialog (action, passthroughVars) {
		return () => {
			let limit = this.state.planName ? SLOTS_PER_PLAN[this.props.id][this.state.planName] : DEFAULT_SLOT_LIMIT;
			switch(action) {
				case "add":
					passthroughVars = passthroughVars || {};
					if (passthroughVars["slotsAdd"] === undefined) {
						passthroughVars["slotsAdd"] = !this.state.scheduleActive;
					}

					if (!passthroughVars["slotsAdd"] && this.scheduleRef.current.getSize() >= limit ||
						passthroughVars["slotsAdd"] && this.slotsRef.current.getSize() >= limit) {
						this.setState({snackbarOpen: true, snackbarMessage: `${MAX_SLOT_ADD_MESSAGE} (max ${limit})`});
					} else {
						let [contentFactory, defaultAttributes, attributeNameMap] = passthroughVars["slotsAdd"] ? this.slotsRef.current.getDefaultAttributesContent() : this.scheduleRef.current.getDefaultAttributesContent();
						this.addDialogRef.current.handleClickOpen({contentFactory: contentFactory, defaultAttributes: defaultAttributes, attributeNameMap: attributeNameMap}, passthroughVars);
					}
					break;
				case "delete":
					if ((this.state.scheduleActive && this.scheduleRef.current.getSize() <= 1)) {
						this.setState({snackbarOpen: true, snackbarMessage: MIN_SLOT_DELETE_MESSAGE});
					} else if (this.state.displayActive || (!this.state.scheduleActive && this.slotsRef.current.getSize() === 0)) {
						this.setState({snackbarOpen: true, snackbarMessage: INVALID_SLOT_DELETE_MESSAGE});
					} else {
						super.openDialog(action, {}, passthroughVars)();
					}
					break;
				default:
					super.openDialog(action, {}, passthroughVars)();
			}
		}
	}
}