import { onSnapshot, updateDoc, doc } from "firebase/firestore"; 
import Slot from './Slot';

export default class DynamicSlot extends Slot {
    constructor(props) {
        super(props);
        onSnapshot(doc(this.props.rootPath, this.props.id), (docSnapshot) => {
            if (docSnapshot.exists()) {
                const data = docSnapshot.data();
                for (let attribute in data.attributes) {
                    this.handleChange(attribute, data.attributes[attribute]);
                }
                if (data.scheduleData !== undefined && data.scheduleData.data !== null) {
                    data.scheduleData.data = JSON.parse(data.scheduleData.data);
                }
                this.scheduleData = data.scheduleData;

            } else {
                console.error("No such document!");
            }
        });
    }

    saveData(data, timeFormat, scheduleStartDay, scheduleEndDay, scheduleStartTime, scheduleEndTime, selectAll) {
        super.saveData(data, timeFormat, scheduleStartDay, scheduleEndDay, scheduleStartTime, scheduleEndTime, selectAll);
        let updateMap = {};
        for (const key in this.props.attributes) {
            if (this.state[key] !== undefined) {
                updateMap[`attributes.${key}`] = this.state[key];
            }
        }
        if (data !== undefined) {
            let scheduleData = {timeFormat: timeFormat, data: JSON.stringify(data), scheduleStartDay: scheduleStartDay, scheduleEndDay: scheduleEndDay, scheduleStartTime: scheduleStartTime, scheduleEndTime: scheduleEndTime, selectAll: selectAll};
            updateMap["scheduleData"] = scheduleData;
            Object.keys(scheduleData).forEach(key => scheduleData[key] === undefined ? delete scheduleData[key] : {});
        }
        updateDoc(doc(this.props.rootPath, this.props.id), updateMap);
    }
}