import React from "react";
import SlotListList from "./SlotListList";
import StaticSlotList from "./StaticSlotList";
import {generateNameSlotContent} from "../utils/SlotContentFactory";
import Slot from "./Slot";
import { defaultSingleSlotWithColor } from "../initial_data";
import {NC_ATTRIBUTE_NAME_MAP} from "../utils/Constants";
import {IncrementingGeneratorFactory} from "../utils/NameGenerators";

export default class StaticSlotListList extends SlotListList {
    constructor(props) {
        super(props);
        this.Slot = <Slot />;
        this.count = 0;
        this.dataAttributes = {};
        let data = this.props.data || [];
        for (var i = 0; i < data.length; i += 1) {
            let key = this.count++;
            this.slotRefs[key] = React.createRef();
            this.dataAttributes[key] = data[i].attributes;
        }
        this.state.orderToSlotIndex = [...Array(data.length).keys()]
        if (this.state.orderToSlotIndex.length > 0) {
            this.state.activeSlotId = this.state.orderToSlotIndex[0];
        }
    }

    getAuxContentList(slotNumber) {
        if (this.slotListRefs[slotNumber] === undefined) {
            this.slotListRefs[slotNumber] = React.createRef();
        }
        let contentList = super.getAuxContentList(slotNumber);
        let color = this.dataAttributes[slotNumber].color || "blue";
        contentList.push(<StaticSlotList key={slotNumber} ref={this.slotListRefs[slotNumber]} data={defaultSingleSlotWithColor(color)} slotClickAction={this.childSlotClickAction(slotNumber)}
            propChangeAction={this.props.propChangeAction} dataChangeAction={this.props.dataChangeAction} timeFormat={this.props.timeFormat}
            selected={this.props.selected && this.state.activeSlotId === slotNumber}  contentFactory={generateNameSlotContent}
            attributeNameMap={NC_ATTRIBUTE_NAME_MAP} rotateColors={false} color={color} nameGenerator={IncrementingGeneratorFactory("Option")}/>);
        return contentList;
    }

    addSlot(slotName) {
        super.addSlot(slotName, this.count++);
    }
}