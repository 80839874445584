import React from 'react';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import PopUpDialog from './PopUpDialog';

export default class SelectionDialog extends PopUpDialog {
    constructor(props) {
        super(props);
        this.state["selectedIndex"] = 0;
    }

    handleClose (success) {
		return () => {
			if (success) {
				this.state.inputState.options[this.state.selectedIndex].action();
			}
			super.handleClose(success)();
		}
	}

    handleSelect = (event) => {
        this.setState({selectedIndex: event.target.value});
    }

    getDialogContent() {
		return <React.Fragment>
			<DialogTitle>{this.props.title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{this.props.description}</DialogContentText>
                <FormControl fullWidth margin='normal'>
                    <InputLabel id="download-options-label">{this.props.label}</InputLabel>
                    <Select
                        labelId="download-options-label"
                        value={this.state.selectedIndex}
                        onChange={this.handleSelect}
                        label={this.props.label}
                    >
                        {this.state.inputState.options && this.state.inputState.options.map((option, index) => (
                            <MenuItem key={index} value={index}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.handleClose(false)}>{this.props.cancelText}</Button>
                <Button onClick={this.handleClose(true)}>{this.props.positiveText}</Button>
			</DialogActions>
		</React.Fragment>
	}
}