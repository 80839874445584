import {MISSING_INPUT_WARNING} from "./Constants";

export const validateInputPresent = (intervalMaps, messages, message) => {
	let returnValue = validateInputPresentHelper(intervalMaps, messages, message);
	if (returnValue.allEmpty) {
		messages.push({severity: "warning", content: message});
		return false;
	} else {
		messages.push(...returnValue.positions.map(position => `${MISSING_INPUT_WARNING}: [${position}]`));
		return true;
	}
}

const validateInputPresentHelper = (intervalMaps, messages, message) => {
	if (!Array.isArray(intervalMaps)) {
		if (Object.values(intervalMaps).filter(value => value.length > 0).length <= 0) {
			return {allEmpty: true, positions: [""]};
		}
		return {allEmpty: false, positions: []};
	} else {
		let allEmpty = true;
		let positions = [];
		for (let i = 0; i < intervalMaps.length; i += 1) {
			let returnValue = validateInputPresentHelper(intervalMaps[i], messages, message);
			allEmpty = allEmpty && returnValue.allEmpty;
			positions.push(...returnValue.positions.map(position => `${i + 1}${position !== "" ? ", " : ""}${position}`));
		}
		return {allEmpty: allEmpty, positions: positions};
	}
}

export const validateAndTruncateInputLength = (intervalsMaps, maxLength, messages, message) => {
	if (validateAndTruncateInputLengthHelper(intervalsMaps, maxLength, messages, message)) {
		messages.push({severity: "warning", content: message});
	}
}

const validateAndTruncateInputLengthHelper = (intervalsMaps, maxLength, messages, message) => {
	let exceeds = false;
	if (Array.isArray(intervalsMaps)) {
		if (intervalsMaps.length > maxLength) {
			intervalsMaps.splice(maxLength);
			exceeds = true;
		}
		for (let i = 0; i < intervalsMaps.length; i += 1) {
			exceeds &= validateAndTruncateInputLengthHelper(intervalsMaps[i], maxLength, messages, message);
		}
	}
	return exceeds;
}