import React from 'react';
import ScheduleCard from '../../components/ScheduleCard';
import StaticSlotList from '../../components/StaticSlotList';
import SchedulerPage from '../SchedulerPage';
import findSolution from '../../solvers/CSolver';
import { getColor } from '../../utils/ColorMapper';
import {generateDaySlotContent, generateTimeSlotContent} from '../../utils/SlotContentFactory';
import {
    COMPLETE_ATTRIBUTE_NAME_MAP,
    UNION_SCHEDULER_NAME
} from '../../utils/Constants';
import { capitalize } from '../../utils/StringUtils';
import { withErrorBoundary } from '../../components/ErrorBoundary';
import {MultiDisplayTable} from "../../components/MultiDisplayTable";
import {SlotListDialogController} from "../../components/DialogControllers/SlotListDialogController";

class UnionSchedulerPage extends SchedulerPage {
    constructor(props) {
        super(props);
        this.componentGenerators = {
            scheduleCard: () => <ScheduleCard icon="/union_icon_free.png" />,
            slotList: () => <StaticSlotList contentFactory={this.state.time ? generateTimeSlotContent : generateDaySlotContent}
                                            attributeNameMap={COMPLETE_ATTRIBUTE_NAME_MAP} />,
            displayTable: () => <MultiDisplayTable/>
        };
    }
    
    findSolution(timesMap, intervalsMaps, metadata) {
        let messages = [];
        if (!this.validateInputs(timesMap, intervalsMaps, messages)) {
            return [{messages: messages}];
        }
        metadata["slotsInfo"] = {...this.slotsRef.current.getSlotsInfo()};

        let slotsInfo = metadata["slotsInfo"];
        let lengthArray;
        if (this.state.time) {
            lengthArray = slotsInfo["hoursArray"].map((hours, index) => ((hours || 0) * 60) + (slotsInfo["minutesArray"][index] || 0));
        } else {
            lengthArray = slotsInfo["daysArray"];
        }
        let {result, missing} = findSolution(timesMap, intervalsMaps, lengthArray, metadata["span"] * metadata["scale"], metadata["wrap"]);
        let solutionData = {};
        if (result === undefined) {
            return [{messages: [{severity: "error", content: "Could not find an arrangement for any slot"}]}];
        }
        for (let i = 0; i < result.length; i += 1) {
            for (let rowIndex in result[i]) {
                if (solutionData[rowIndex] === undefined) { 
                    solutionData[rowIndex] = [];
                }
                let array = solutionData[rowIndex];
                for (let interval of result[i][rowIndex]) {
                    array.push({interval: interval, borderColor: getColor(slotsInfo["colorArray"][i], 800), color: getColor(slotsInfo["colorArray"][i], 500), label: [slotsInfo["nameArray"][i]]})
                }
            }
        }
        if (missing.length > 0) {
            messages.push({severity: "warning", content: "Could not schedule: " + missing.map((index) => slotsInfo["nameArray"][index]).join(", ")});
        }
        return [{messages: messages, table: {solutionData: solutionData}}];
    }

    componentDidMount() {
        super.componentDidMount();
        document.title = `${capitalize(UNION_SCHEDULER_NAME)} scheduler`;
    }
}

export default withErrorBoundary(SlotListDialogController(UnionSchedulerPage));