import React from "react";
import SlotListList from "./SlotListList";
import DynamicSlot from "./DynamicSlot";
import DynamicSlotList from "./DynamicSlotList";
import {generateNameSlotContent} from "../utils/SlotContentFactory";
import { doc, collection, onSnapshot, query, orderBy, addDoc, runTransaction } from "firebase/firestore";
import { db } from "../firebase"
import {nextChar, FIRST_CHAR, findAppendedOrder} from '../utils/OrderEncoderUtils'
import {NC_ATTRIBUTE_NAME_MAP, OPERATION_FAILED, WRITE_FAILED_DETAIL} from "../utils/Constants";
import {IncrementingGeneratorFactory} from "../utils/NameGenerators";


export default class DynamicStaticSlotListList extends SlotListList {
    constructor(props) {
        super(props);
        this.Slot = <DynamicSlot rootPath={this.props.rootPath}/>;
        this.nameGenerator = IncrementingGeneratorFactory("Option");
        this.order = {};
        onSnapshot(query(this.props.rootPath, orderBy("order")), (querySnapshot) => {
            let orderToSlotIndex = [];
            querySnapshot.forEach((doc) => {
                let data = doc.data();
                this.dataAttributes[doc.id] = this.getDefaultAttributes();
                for (let key of Object.keys(data.attributes)) {
                    this.dataAttributes[doc.id][key] = data.attributes[key];
                }
                this.slotRefs[doc.id] = this.slotRefs[doc.id] || React.createRef();
                orderToSlotIndex.push(doc.id);
                this.order[doc.id] = data.order;
                //console.log(`${doc.id} => ${JSON.stringify(data)}`);
            })
            this.setState({orderToSlotIndex: orderToSlotIndex, activeSlotId: this.state.activeSlotId || orderToSlotIndex[orderToSlotIndex.length - 1]});
            if (orderToSlotIndex.length === 0) { //can't rely on child to unblock
                this.props.onLoadFinish();
            }
        }, () => {
            this.props.propChangeAction({"notFound": true});
        });
    }

    getAuxContentList(slotNumber) {
        if (this.slotListRefs[slotNumber] === undefined) {
            this.slotListRefs[slotNumber] = React.createRef();
        }
        let contentList = super.getAuxContentList(slotNumber);
        let color = this.dataAttributes[slotNumber].color || "blue";
        contentList.push(<DynamicSlotList key={slotNumber} ref={this.slotListRefs[slotNumber]} data={this.props.data} slotClickAction={this.childSlotClickAction(slotNumber)}
            propChangeAction={this.props.propChangeAction} dataChangeAction={this.props.dataChangeAction} displayMessage={this.props.displayMessage}
            timeFormat={this.props.timeFormat} selected={this.props.selected && this.state.activeSlotId === slotNumber}
            contentFactory={generateNameSlotContent} rootPath={collection(this.props.rootPath, slotNumber, "slots")}
            onLoadFinish={this.props.onLoadFinish} rotateColors={false} attributeNameMap={NC_ATTRIBUTE_NAME_MAP} color={color}
            nameGenerator={this.nameGenerator} />);
        return contentList;
    }

    addSlot(attributes) {
        let orders = this.state.orderToSlotIndex.map((slotNumber) => this.order[slotNumber]);
        let newSlotKey = findAppendedOrder(orders);

        let newAttributes = this.getDefaultAttributes();
        for (let key in attributes) {
            newAttributes[key] = attributes[key];
        }

        addDoc(this.props.rootPath, {
            attributes: newAttributes,
            order: newSlotKey
        }).then((docRef) => {
            addDoc(collection(this.props.rootPath, docRef.id, "slots"), {
                attributes: {name: this.nameGenerator([]), color: newAttributes.color},
                order: nextChar(FIRST_CHAR)
            }).then(() => {
                this.switchSlot(docRef.id);
            }).catch(() => {
                this.props.displayMessage(`${OPERATION_FAILED} ${WRITE_FAILED_DETAIL}`);
            });
        }).catch(() => {
            this.props.displayMessage(`${OPERATION_FAILED} ${WRITE_FAILED_DETAIL}`);
        });
    }

    deleteActiveSlot() {
        let activeSlotId = this.state.activeSlotId;
        if (!this.props.selected || activeSlotId === undefined) {
            return undefined;
        }
        let orderLength = this.slotListRefs[activeSlotId].current.state.orderToSlotIndex.length;
        let firstSubElementId = this.slotListRefs[activeSlotId].current.state.orderToSlotIndex[0];
        let newSlotId = super.deleteActiveSlot();

        if (orderLength <= 1) {
            runTransaction(db, async (transaction) => {
                transaction.delete(doc(this.props.rootPath, activeSlotId, "slots", firstSubElementId));
                transaction.delete(doc(this.props.rootPath, activeSlotId));
            });
        } else {
            this.slotListRefs[activeSlotId].current.deleteActiveSlot();
        }

        return newSlotId;
    }

    saveDataToActive(data, timeFormat, scheduleStartDay, scheduleEndDay, scheduleStartTime, scheduleEndTime, selectAll) {
        if (this.slotRefs[this.state.activeSlotId] !== undefined) { //may occur if last slot was just deleted
            this.slotRefs[this.state.activeSlotId].current.saveData(undefined); //save field attributes but not data
            super.saveDataToActive(data, timeFormat, scheduleStartDay, scheduleEndDay, scheduleStartTime, scheduleEndTime, selectAll);
            this.slotListRefs[this.state.activeSlotId].current.pushAllAttributes();
        }
    }
}