export const defaultMultiData = [
    {attributes: {name: "Customer 1", length: 30, color: "blue"}},
    {attributes: {name: "Customer 2", length: 12, color: "red"}},
    //{name: "Customer 3", length: "20", granularity: "minutes", color: "brown"},
    //{name: "Customer 4", length: "18", granularity: "minutes", color: "green"},
    //{name: "Customer 5", length: "30", granularity: "minutes", color: "blue"},
    //{name: "Customer 6", length: "12", granularity: "minutes", color: "red"},
    //{name: "Customer 7", length: "20", granularity: "minutes", color: "brown"},
    //{name: "Customer 8", length: "18", granularity: "minutes", color: "green"}
]

export const defaultSingleSlotWithColor = (color) => {
    return [{attributes: {color: color}}]
}

//set startDate to YYYY-MM-DD of today's date
//set endDate to YYYY-MM-DD of the date 7 days from today
//set startTime to HH:MM of 8:00 AM
//set endTime to HH:MM of 6:00 PM
const today = new Date();
export const defaultStartDate = today.toISOString().split('T')[0];
const endDateObj = new Date();
endDateObj.setDate(today.getDate() + 7);
export const defaultEndDate = endDateObj.toISOString().split('T')[0];

export const defaultStartTime = "08:00";
export const defaultEndTime = "18:00";