import React from 'react';
import ScheduleCard from '../../components/ScheduleCard';
import StaticSlotList from '../../components/StaticSlotList';
import SchedulerPage from '../SchedulerPage';
import findSolution from '../../solvers/ISolver';
import { getColor } from '../../utils/ColorMapper';
import {generateNameColorSlotContent} from '../../utils/SlotContentFactory';
import {
    INTERSECTION_SCHEDULER_NAME,
    NC_ATTRIBUTE_NAME_MAP
} from '../../utils/Constants';
import { capitalize } from '../../utils/StringUtils';
import {MultiDisplayTable} from "../../components/MultiDisplayTable";
import {withErrorBoundary} from "../../components/ErrorBoundary";
import {SlotListDialogController} from "../../components/DialogControllers/SlotListDialogController";

class IntersectionSchedulerPage extends SchedulerPage {
    constructor(props) {
        super(props);
        this.componentGenerators = {
            scheduleCard: () => <ScheduleCard icon="/intersection_icon_free.png"/>,
            slotList: () => <StaticSlotList contentFactory={generateNameColorSlotContent}
                                            attributeNameMap={NC_ATTRIBUTE_NAME_MAP} />,
            displayTable: () => <MultiDisplayTable showUnit={"Count"} showFn={(label) => label.length}/>
        }
    }

    // {0: [{interval: [a, b], color: {"blue[700]"}, label: {0, 1, 2}}, {interval: [c, d], color: {"blue[700]"}, label: {0, 1, 2}}], 5: [{interval: [e, f], color: {"blue[700]"}, label: {1, 2}}]}
    findSolution(timesMap, intervalsMaps, metadata) {
        let messages = [];
        if (!this.validateInputs(timesMap, intervalsMaps, messages)) {
            return [{messages: messages}];
        }
        metadata["slotsInfo"] = {...this.slotsRef.current.getSlotsInfo()};

        let slotsInfo = metadata["slotsInfo"];
        let solution = findSolution(timesMap, intervalsMaps);
        for (let rowIndex in solution) {
            for (let data of solution[rowIndex]) {
                let level = data.set.size / slotsInfo["nameArray"].length * 800 + 100;
                data.color = getColor("green", level);
                data.borderColor =  getColor("green", level + 100);
                data.label = Array.from(data.set).map(index => slotsInfo["nameArray"][index]);
                delete data["set"]
            }
        }
        return [{table: {solutionData: solution}, messages: messages}];
    }

    componentDidMount() {
        super.componentDidMount();
        document.title = `${capitalize(INTERSECTION_SCHEDULER_NAME)} scheduler`;
    }
}

export default withErrorBoundary(SlotListDialogController(IntersectionSchedulerPage));