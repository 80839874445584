import React from 'react';
import DynamicScheduleCard from '../../components/DynamicScheduleCard';
import DynamicSlotList from '../../components/DynamicSlotList';
import DynamicSchedulerPage from './DynamicSchedulerPage';
import {generateNameColorSlotContent} from '../../utils/SlotContentFactory';
import { capitalize } from '../../utils/StringUtils';
import {
    ERROR_SOLUTION_MESSAGE,
    NC_ATTRIBUTE_NAME_MAP,
    INTERSECTION_SCHEDULER_NAME
} from '../../utils/Constants';
import { withErrorBoundary } from '../../components/ErrorBoundary';
import {getColor} from "../../utils/ColorMapper";
import {DynamicTabbedMultiDisplayTable} from "../../components/DynamicTabbedMultiDisplayTable";
import {SlotListDialogController} from "../../components/DialogControllers/SlotListDialogController";

class DynamicIntersectionSchedulerPage extends DynamicSchedulerPage {
    constructor(props) {
        super(props);
        this.componentGenerators = {
            scheduleCard: () => <DynamicScheduleCard key="scheduleCard" icon="/intersection_icon.png"  {...this.scheduleProps()}/>,
            slotList: () => <DynamicSlotList key="slotList" contentFactory={generateNameColorSlotContent} attributeNameMap={NC_ATTRIBUTE_NAME_MAP} {...this.slotProps()}/>,
            displayTable: () => <DynamicTabbedMultiDisplayTable key="displayTable" showUnit={"Count"} showFn={(label) => label.length} {...this.displayProps()} />
        }
    }

    async findSolution(timesMap, intervalsMaps, metadata) {
        let messages = [];
        if (!this.validateInputs(timesMap, intervalsMaps, messages)) {
            return [{messages: messages}];
        }

        metadata["slotsInfo"] = {...this.slotsRef.current.getSlotsInfo()};
        let slotsInfo = metadata["slotsInfo"];
        let slots = intervalsMaps.map((intervalsMap) => { return {"rows": intervalsMap }});
        let input = {"schedule":{"rows": timesMap}, "slots": slots};
        //console.log(timesMap, intervalsMaps);
        let response = await this.callSolver("common_time", input);
        //console.log("response", response);
        if (response === undefined) {
            return [{messages: [{severity: "error", content: ERROR_SOLUTION_MESSAGE}]}];
        }
        let solutionData = {};
        for (let rowIndex of Object.keys(response.schedule.table)) {
            solutionData[rowIndex] = response.schedule.table[rowIndex].map((entry) => {
                let indices = entry.data.indices;
                let level = indices.length / slotsInfo["nameArray"].length * 800 + 100;
                return {"interval": [entry.start, entry.end], "borderColor": getColor("green", level + 100), "color": getColor("green", level), "label": indices.map(index => slotsInfo["nameArray"][index])};
            });
        }
        return [{table: {solutionData: solutionData}}];
    }

    componentDidMount() {
        super.componentDidMount();
        document.title = `${capitalize(INTERSECTION_SCHEDULER_NAME)} scheduler | Snug Scheduler`;
    }
}

export default withErrorBoundary(SlotListDialogController(DynamicIntersectionSchedulerPage));